.nav-bar {
  width: 100%;
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  box-shadow: 0 5px 20px -10px rgba(0, 0, 0, 0.2);
}

.baya-small {
  width: 30px;
  height: 35px;
  margin-left: 50px;
  cursor: pointer;
}

.nav {
  list-style-type: none;
  margin: 0;
}

.nav-item {
  display: inline-block;
  margin-right: 50px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

.selected {
  color: #414042;
  text-decoration: none;
}

.selected:hover {
  color: red;
}

.social-icon {
  color: green;
  font-size: 24px;
  position: relative;
  top: 3px;
  right: 6px;
}

/* Phones */
@media only screen and (max-width: 769px) {
  .contact-select {
    display: none;
  }

  .baya-small {
    margin-left: 30px;
  }

  .nav-item {
    margin-right: 30px;
  }
}
