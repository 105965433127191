#residents {
  background-color: white;
  scroll-margin-block-start: 120px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 100px 30px;
  gap: 20px;
}

.text-block {
  width: 200px;
  position: absolute;
}

.feed-block {
  position: relative;
  margin-top: 250px;
  z-index: 1;
}

.payments-block {
  position: absolute;
  z-index: 2;
  margin-top: 100px;
  margin-left: -40px;
}

.residents-images {
  width: 220px;
  border-radius: 20px;
  box-shadow: 0.1em 0.1em 1em lightgray;
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  transform: rotate(5deg);
}

.visitors-block {
  position: relative;
  margin-left: 175px;
  margin-top: -300px;
  z-index: 3;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.accordion {
  font-size: 10px;
  float: right;
  margin-right: 5px;
}

.accordion-button {
  height: 50px;
  padding: 6px;
  font-size: 15px;
}

.accordion-body {
  font-size: 14px;
  padding: 6px;
}

:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  border: 1px solid;
}

/* Tablets */
@media only screen and (min-width: 1200px) {
  .residents-left {
    width: 650px;
  }
  .accordion {
    width: 65%;
  }
}

@media only screen and (max-width: 1200px) {
  .residents-images {
    width: 250px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .visitors-block {
    display: none;
  }

  .payments-block {
    display: none;
  }

  .accordion {
    width: 100%;
  }
}

/* Phones */
@media only screen and (max-width: 769px) {
  .residents-left {
    margin: auto;
  }
  .feed-block {
    width: 100%;
  }
}
