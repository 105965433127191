#security {
  background-color: white;
  scroll-margin-block-start: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 100px 30px;
  gap: 20px;
}

.tablet-block {
  width: 200px;
  position: absolute;
  margin-top: 50px;
}

.hazard-block {
  position: absolute;
  left: 250px;
}

.customer-block {
  margin-top: 270px;
  position: relative;
  z-index: 2;
}

.security-images {
  width: 480px;
  border-radius: 20px;
  box-shadow: 0.1em 0.1em 1em lightgray;
}

/* Tablets */
@media only screen and (max-width: 1200px) {
  .hazard-block {
    display: none;
  }
}

@media only screen and (max-width: 1020px) {
  .customer-block {
    width: 100%;
  }
}

/* Phones */
@media only screen and (max-width: 769px) {
  .security-left {
    margin: auto;
  }

  .tablet-block {
    margin-top: 0px;
  }
}
