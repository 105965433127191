#contact {
  background-color: #f8fcfc;
  scroll-margin-block-start: 10px;
}

.contact-grid {
  display: flex;
  justify-content: space-between;
}

.left-column {
  margin-left: 10px;
  margin-right: 10px;
}

.contact-us {
  width: 300px;
  margin-top: 70px;
}

.contact-details {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin-left: 20px;
}

a {
  color: blue;
  text-decoration: underline;
}

.form-column {
  margin-top: 90px;
  margin-left: 30px;
  margin-right: 30px;
}

.form-cont {
  margin-bottom: 20px;
}

.input-fields {
  font-size: 15px;
  padding: 15px 12px;
  background-color: white;
  border: 1px solid lavender;
  border-radius: 6px;
  outline: none;
}

.input-size {
  width: 450px;
  resize: none;
}

.input-fields:focus {
  border: 1px solid #6e7a84;
}

.input-label {
  font-size: 17px;
}

.send-button {
  font-size: 17px;
  padding: 15px 30px;
  color: #ffffff;
  background-color: black;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.send-button:active {
  border: 1px solid #ff3333;
  background-color: #ff3333;
}

.error-message {
  font-size: 14px;
  color: red;
  font-weight: lighter;
  margin-top: 0.3rem;
}

.errors {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1500px) {
  .input-size {
    width: 530px;
  }
}

@media only screen and (max-width: 1200px) {
  .input-size {
    width: 400px;
  }
}

@media only screen and (max-width: 1020px) {
  .contact-grid {
    flex-direction: column;
    margin: auto;
  }
}

@media only screen and (max-width: 769px) {
  .input-size {
    width: 100%;
  }

  .contact-us {
    width: 280px;
  }

  .contact-details {
    font-size: 16px;
  }

  .left-column {
    margin: auto;
  }

  .right-column {
    margin: auto;
  }
}
