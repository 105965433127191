/* XXL */
@media only screen and (min-width: 1960px) {
  .center-div {
    margin-left: 50px;
    margin-top: 200px;
  }

  .accordion {
    width: 80%;
  }
}

@media only screen and (min-width: 1500px) {
  .center-div {
    margin-top: 60px;
    margin-left: 100px;
  }

  .baya-logo {
    width: 160px;
  }

  .baya-manager {
    font-size: 105px;
  }

  .baya-estate {
    font-size: 25px;
  }

  .scroll {
    font-size: 18px;
  }

  #residents {
    margin-left: 80px;
  }

  #security {
    margin-left: 80px;
  }

  #dashboard {
    margin-left: 80px;
  }

  .platform-logo {
    width: 45%;
  }

  .left-column {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1370px) {
  #residents {
    margin-left: 50px;
  }

  #security {
    margin-left: 50px;
  }

  #dashboard {
    margin-left: 50px;
  }

  .left-column {
    margin-left: 20px;
  }
}
