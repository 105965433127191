.swiper-container {
  width: 100%;
}

.swiper-wrapper {
  padding-inline-start: 0;
}

.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lavender;
  background-color: #e7e9e4;
  background-image: url("../../images/baya-bird-dup.jpg");
  background-size: 100% 100%;
}

.left-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.left-image {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  background-size: contain;
  background-position: center;
  background-color: white;
}

.right-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.center-div {
  width: 350px;
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: 100px;
}

.baya-logo {
  margin-top: 90px;
  width: 100px;
  margin-left: 50px;
}

.baya-manager {
  font-size: 85px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: -7px;
  color: black;
}

.baya-estate {
  font-size: 16px;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  color: crimson;
  margin-top: 0px;
  margin-bottom: 25px;
  letter-spacing: 6px;
}

.scroll {
  font-size: 13px;
  color: #414042;
  text-decoration: none;
}

.scroll:hover {
  color: crimson;
  transition: all 1s ease;
  margin-left: 2px;
}

.checker {
  font-size: 18px;
}

.platform-logo {
  width: 35%;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.apple {
  margin-right: 20px;
}

/* Tablets */
@media only screen and (min-width: 1200px) {
  .center-div {
    width: 440px;
  }

  .tablets {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .fm-admin {
    display: none;
  }
}

/* Phones */
@media only screen and (max-width: 769px) {
  .left-container {
    display: none;
  }

  .fm-admin {
    display: none;
  }

  .center-div {
    margin-left: 0px;
  }

  .baya-logo {
    width: 110px;
    margin-top: 100px;
  }

  .scroll {
    font-size: 16px;
  }

  .platform-logo {
    width: 45%;
  }
}
