#dashboard {
  background-color: white;
  scroll-margin-block-start: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 100px 30px;
  gap: 20px;
}

.fm-block {
  width: 250px;
  position: absolute;
}

.dashboard-block {
  position: relative;
  margin-top: 200px;
  z-index: 1;
}

.residents-block {
  position: absolute;
  left: 300px;
}

.dashboard-images {
  width: 470px;
  border-radius: 20px;
  box-shadow: 0.1em 0.1em 1em lightgray;
}

/* Tablets */
@media only screen and (max-width: 1200px) {
  .residents-block {
    display: none;
  }
}

@media only screen and (max-width: 1020px) {
  .dashboard-block {
    width: 100%;
  }
}

/* Phones */
@media only screen and (max-width: 769px) {
  .dashboard-left {
    margin: auto;
  }
}
